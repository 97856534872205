nav {
    box-shadow: 0 2px 2px -2px rgba(0, 0, 0, .2);
}

.navbar-brand {
    margin-left: 2rem;
}

.navbar .navbar-collapse {
    text-align: center;
}

a.nav-link {
    position: relative;
    text-decoration: none;
    margin: auto;
}

nav:not(.footer) a.nav-link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #898989;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out 0s;
}

nav:not(.footer) a.nav-link:hover::before {
    visibility: visible;
    transform: scaleX(1);
}

/* Phone Icon */


/* Light navbar */

.navbar-light {
    background-color: whitesmoke;
    box-shadow: 0 2px 10px 0px #cacaca;
}

@keyframes startLight {
    from {
        background-color: #2f557c;
        box-shadow: 0 2px 10px 0px #212529;
    }
    to {
        background-color: whitesmoke;
        box-shadow: 0 2px 10px 0px #cacaca;
    }
}

.navbar-light .navbar-nav .nav-link {
    color: rgb(97, 92, 92) !important;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: rgb(24, 23, 23) !important
}

/* Dark navbar */

.navbar-dark {
    background-color: #2f557c;
    box-shadow: 0 2px 10px 0px #212529;
}

@keyframes startDark {
    from {
        background-color: whitesmoke;
        box-shadow: 0 2px 10px 0px #cacaca;
    }
    to {
        background-color: #2f557c;
        box-shadow: 0 2px 10px 0px #2f557c;
    }
}

.navbar-dark .navbar-nav .nav-link {
    color: rgb(209, 209, 209) !important;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: rgb(255, 255, 255) !important
}

/* Continue */

.navbar-nav .nav-link #phoneIcon {
    font-size: 1.5rem;
    margin-left: 3px;
}