.section .text {
    text-align: center;
}

.pictures {
    padding-top: 40px;
}

.pictures img {
    -webkit-box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.54);
    -moz-box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.54);
    box-shadow: 0px 0px 9px 0px rgba(50, 50, 50, 0.54);
    border-radius: 10%;
}

.pictures img:hover {
    -webkit-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.54);
    -moz-box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.54);
    box-shadow: 0px 0px 3px 0px rgba(50, 50, 50, 0.54);
}

.pictures p {
    padding-top: 10px;
    font-size: 90%;
    font-weight: 400;
}

.aboutInfo {
    padding-top: 10px;
}

.aos-anim {
    overflow-x: hidden !important;
}

.aos-anim img {
    padding-top: 45px;
}