#services {
    background: #7fc7f6;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
}

#services .animateImage img { 
    padding-top: 35px;
}

#services .preText {
    font-size: 20px;
}

#services .text {
    padding-top: 40px;
}

.servicesList {
    text-align: right;
}

@media only screen and (min-width:85.25em) {
    #services .title {
        margin-top: -60px;
    }
}

.list-group-item {
    background-color: #7fc7f6 !important;
    border-width: 0 0 0px !important;
}