.wrapper section {
    /* Text */
    justify-content: center;
    text-align: justify;
    align-items: center;
    display: flex;
    flex-direction: column;
    /* Image */
    background-image: url("/images/bg-main.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.wrapper section>* {
    position: relative;
    z-index: 2;
}

.wrapper section:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.wrapper section p.title {
    color: white;
    font-size: 5vw;
    font-weight: bold;
}

.wrapper section p.text {
    color: rgb(223, 223, 223);
    font-size: 3vw
}

/*TODO: HR 
.wrapper section hr {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.75), rgba(0, 0, 0, 0));
    width: 50%
}*/