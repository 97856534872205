nav.footer {
    background-color: #2f557c;
}

nav.footer div.nav-item {
    color: #d1d1d1;
}

nav.footer div.nav-item a.ref {
    text-decoration: none;
    color: #90b7ff;
}

nav.footer div.nav-item a.ref:hover {
    color: #7ea0df;
}